const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const displayPublishAt = (value: string) => {
  const date = new Date(value);
  return `${date.getDate()} ${months[date.getMonth()]} ${date.getFullYear()}`;
};

export const truncate = (text: string, count: number) => {
  if (text.length <= count) {
    return text;
  }
  const subString = text.substr(0, count - 1);
  return subString.substr(0, subString.lastIndexOf(' ')) + ' ...';
};

export const maxDate = (strings: string[]) => {
  const times = strings.map((s) => s ? new Date(s).getTime() : 0);
  return strings[times.indexOf(Math.max(...times))]
}

export const logHowLong = async (label: string, fn: () => Promise<any>) => {
  const start = new Date().getTime();
  const result = await fn();
  const finish = new Date().getTime();
  console.log(`${label}: ${finish - start}`);

  return result;
}