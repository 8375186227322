export default {
  'none': 'None',
  'utility': 'Utility',
  'customer-type': 'Customer type',
  'residential': 'Home',
  'commercial': 'Business',
  'zone': 'Zone',

  'nav.home': 'Home',
  'nav.product': 'Product',
  'nav.hardware': 'Hardware',
  'nav.software': 'Software',
  'nav.incentives': 'Incentives',
  'nav.blog': 'Blog',
  'nav.calculator': 'Calculate your profit',
  'nav.about': 'About Us',
  'nav.privacy': 'Privacy Policy',
  'nav.terms': 'Terms of Use',
  'nav.terms-service': 'Terms of Service',
  'nav.terms-conditions': 'Terms and Conditions',
  'nav.contact': 'Contact Us',
  'nav.services': 'Services',
  'nav.our-services': 'Our services',
  'nav.providers': 'Providers',
  'nav.faq': 'FAQ',

  'services.retail-energy': 'Retail Energy',
  'services.ev-charging': 'EV Charging',
  'services.lighting-retrofit': 'Lighting Retrofit',

  'footer.about': 'Energy5 EV Charging solutions comprise a full range of end-to-end turnkey services for businesses. From permitting to incentive acquisition to installation, management software, and down-the-road maintenance, Energy5 streamlines the whole process every step of the way.',
  'footer.powered-by': 'Powered by',
  // TODO: remove in future
  'footer.address': 'Address',
  'footer.email-address': 'Email address',
  'footer.phone-number': 'Phone number',

  'faq.title': 'Frequently asked questions',

  'form.label.email': 'Email address',
  'form.placeholder.email': 'Enter email',
  'form.label.phone': 'Phone number',
  'form.placeholder.phone': 'Enter phone number',
  'form.label.address': 'Address',
  'form.placeholder.address': 'House number, street name',
  'form.label.extendedAddress': 'Extended address',
  'form.placeholder.extendedAddress': 'Apt./Room',
  'form.label.country': 'Country',
  'form.placeholder.country': 'Your country',
  'form.label.county': 'County',
  'form.placeholder.county': 'Your county',
  'form.label.state': 'State',
  'form.placeholder.state': 'Select state',
  'form.label.city': 'City',
  'form.placeholder.city': 'Your city',
  'form.label.zipCode': 'Zip code',
  'form.label.name': 'Name',
  'form.label.firstName': 'First name',
  'form.placeholder.firstName': 'Type your first name',
  'form.label.middleName': 'Middle name',
  'form.placeholder.middleName': 'Type your middle name',
  'form.label.lastName': 'Last name',
  'form.placeholder.lastName': 'Type your last name',
  'form.label.fullName': 'Full name',
  'form.label.fullName-placeholder': 'First & Last name',
  'form.label.workEmail': 'Work email',
  'form.label.companyName': 'Company name',
  'form.label.message': 'Message',
  'form.placeholder.message': 'Enter your message',
  'form.label.message-placeholder': 'Type here',
  'form.label.agree-receive': 'By submitting this form you agree to receive text messages. Your information will not be shared with any third party.',

  'form.error.required': 'please fill this field',
  'form.error.email': 'please enter correct email',
  'form.error.contacts': 'fill out at least one of this form bellow',
  'form.response.success': 'Your data was submitted successfully',
  'form.response.500': 'Internal server error',
  'form.response.401': 'Unauthorized',
  'form.response.403': 'Forbidden',
  'form.response.unknown': 'Unknown error was happened',

  'form.lead.title': 'Get A Free Consultation',
  'form.lead.intro1':
    'Fill in our contact form, and we will get back to you shortly with all the details on how Energy5 can integrate the',
  'form.lead.intro2': 'EV Charging infrastructure',
  'form.lead.intro3': 'into your business!',

  'form.lead.name': 'Full Name',
  'form.lead.email': 'Email Address',
  'form.lead.phone': 'Phone Number',
  'form.lead.comments': 'Comments',
  'form.lead.type': 'Type here',
  'form.lead.button': 'Request a callback',

  'action.add': 'Add',
  'action.save': 'Save',
  'action.change': 'Change',
  'action.start': 'Start',
  'action.explore': 'Explore',
  'action.prev': 'Prev',
  'action.next': 'Next',
  'action.submit': 'Submit',
  'action.confirm': 'Confirm',
  'action.back': 'Back',
  'action.close': 'Close',
  'action.continue': 'Continue',
  'action.sort-by': 'Sort by',
  'action.send': 'Send',
  'action.view': 'View',
  'action.cancel': 'Cancel',
  'action.moreDetails': 'More details',

  'document': 'Document',
  'document.text1': 'Help us improve the quality of our future services by sending your feedback to',
  'document.text2': 'We’ll be happy to review your concerns promptly!',
  'document.sincerely': 'Sincerely, Team Energy5',
  'document.start-from': 'Start from',
  'document.home-page': 'home page',
}
