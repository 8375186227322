import { FindProgramAccordionItemProps } from 'evcharging/containers/ev-rebates/FindProgramAccordionItem';
import {
  ActiveCategoryGroup,
  BlogPost,
  CreatePostCommentRequest,
  E5Service, GroupedPosts,
  PageInterface,
  PostCategory,
  PostCollection,
  RetailLocation,
  RssFeed,
  ShopProduct,
  SitemapItem
} from 'evcharging/services/types';
import { HttpMethod } from 'evcharging/app/http';
import { ENV, HOST_URL, I18N_DEFAULT_LOCALE, SITEMAP_PAGE_SIZE } from 'evcharging/app';
import { ENERGY_RATES_ROUTE } from 'config/retail/routes';
import { maxDate } from 'utils/date';
import { truncateBeforeBanners } from 'utils/common/string';
import { randomInt } from 'utils/common/numbers';

const CMS_URL = 'https://admin.energy5.com';
const RESOURCE_BASE_URL = CMS_URL + '/items';

const CMS_WRITE_URL = 'https://admin-rw.energy5.com';
const RESOURCE_BASE_WRITE_URL = CMS_WRITE_URL + '/items';

const STATES_COLLECTION = 'evc_states';
const STATES_QUERY = (locale: string) =>
  `fields=*,utilities.*,rebates.*&filter={"locale":{"_eq":"${locale}"}}`;

const PAGES_COLLECTION = 'evc_pages';
const PAGES_QUERY = (link: string) => `fields=*,faqs.*&filter={"link":{"_eq":"${link}"}}`;
const PAGES_SITEMAP_QUERY = `fields=link,lastmod,locale&filter={"external":{"_eq":false}}`;

export const BLOG_PAGE_SIZE = 29;
export const BLOG_COMMENTS_PAGE_SIZE = 15;
export const BLOG_RELATED_ARTICLES_SIZE = 8;
export const SERVICE_ARTICLES_LIMIT = 9;

const BLOG_COLLECTION = 'evc_blog';

const BLOG_BY_CATEGORY_ID_JOIN = ({ locale, limit, offset, categoryId }: Record<string, string | number>) => {
  return `fields=id,title,image,link&meta=*&filter={"_and":[{"status":{"_eq":"published"}},{"publish_at":{"_lte":"now"}},{"locale":{"_eq":"${locale}"}},{"_or":[{"categories":{"evc_blog_categories_id":{"_eq":"${categoryId}"}}},{"active_category":{"_eq":"${categoryId}"}}]}]}&sort=-publish_at&limit=${limit}&offset=${offset}`;
};
const BLOG_BY_POST_IDS_QUERY = (
  ids: string[],
  limit: number,
  offset = 0,
  activeCategoryId = '',
  locale = 'en'
) => {
  const parts = [];
  if (ids.length) {
    parts.push({ id: { _in: ids } });
  }
  if (activeCategoryId) {
    parts.push({ active_category: { _eq: activeCategoryId } });
  }
  const condition = JSON.stringify(parts);
  return `fields=*,active_category.*,faqs.*,sections.*,categories.evc_blog_categories_id.*&meta=*&filter={"_or":${condition},"status":{"_eq":"published"},"publish_at": {"_lte":"now"},"locale":{"_eq":"${locale}"}}&sort=-publish_at&limit=${limit}&offset=${offset}`;
};
const BLOG_SITEMAP_QUERY = (limit: number, offset: number) =>
  `fields=link,original,locale,date_updated,publish_at,title,image,summary,comments.publish_at&filter={"status":{"_eq":"published"},"publish_at": {"_lte":"now"}}&limit=${limit}&offset=${offset}&sort=publish_at&deep={"comments":{"_limit": 1,"_sort":"-publish_at"}}`;
const BLOG_ORIGINALS_QUERY = (originals) => {
  const filter = JSON.stringify({ original: { _in: originals } });
  return `fields=link,original,locale&filter=${filter}`;
};
const BLOG_RSS_QUERY = (locale: string) =>
  `fields=link,locale,date_updated,publish_at,title,image,summary&filter={"locale":{"_eq":"${locale}"},"status":{"_eq":"published"},"publish_at": {"_lte":"now"}}&limit=200&sort=-publish_at`;
const BLOG_CATEGORIES_LINKS_QUERY = `fields=id,link,original,locale&limit=10000&sort=id`;
const BLOG_SITEMAP_LINKS_QUERY = `aggregate[max]=publish_at,date_updated&groupBy=active_category`;
const BLOG_SITEMAP_COUNT_QUERY = `aggregate[count]=*&filter={"status":{"_eq":"published"},"publish_at": {"_lte":"now"}}`;

const BLOG_CATEGORIES_COLLECTION = 'evc_blog_categories';

const BLOG_BELONGS_TO_PAGES_COLLECTION = 'evc_blog_evc_pages';
const BLOG_PAGE_RELATED_POST_IDS_QUERY = (pageIds: string[]) =>
  `fields=evc_blog_id&filter={"evc_pages_id":{"_in":${JSON.stringify(pageIds)}}}`;

const POST_COMMENTS_COLLECTION = 'evc_blog_comments';

const RETAIL_LOCATIONS_COLLECTION = 'energy5_retail_locations';
const RETAIL_LOCATIONS_BY_SLUG_QUERY = (slug: string) =>
  `filter=${JSON.stringify({ slug: { _eq: slug } })}`;
const RETAIL_LOCATIONS_BY_ZIP_CODE_QUERY = (zipCode: string) =>
  `filter=${JSON.stringify({ zip_code: { _eq: zipCode } })}`;
const RETAIL_NEAR_LOCATIONS_QUERY = (zipCode: string) =>
  `limit=${10}&filter=${JSON.stringify({
    _and: [{ zip_code: { _starts_with: zipCode.slice(0, 3) } }, { zip_code: { _neq: zipCode } }]
  })}`;
const RETAIL_LOCATIONS_SITEMAP_COUNT_QUERY = `aggregate[count]=*`;
const RETAIL_LOCATIONS_SITEMAP_QUERY = (limit: number, offset: number, sort: string) =>
  `limit=${limit}&offset=${offset}&sort=${sort}`;

const resourceUrl = (collection: string, query = '') =>
  `${RESOURCE_BASE_URL}/${collection}${query ? `?${query}` : ''}`;

const resourceWriteUrl = (collection: string, query = '') =>
  `${RESOURCE_BASE_WRITE_URL}/${collection}${query ? `?${query}` : ''}`;

export const assetUrl = (id: string, ext = 'webp') => `https://direct.energy5.com/${id}.${ext}`;

export const getRebates = async (locale: string): Promise<FindProgramAccordionItemProps[]> => {
  const response = await fetch(resourceUrl(STATES_COLLECTION, STATES_QUERY(locale)));
  if (response.ok) {
    const { data } = await response.json();
    return data.map((state: Record<string, any>) => ({
      title: `${state.code}, ${state.name}`,
      body: [
        ...state.rebates.map((rebate: Record<string, any>, index: number) => ({
          header: index ? null : 'rebates.state',
          title: rebate.name,
          body: rebate.text
        })),
        ...state.utilities.map((rebate: Record<string, any>, index: number) => ({
          header: index ? null : 'rebates.utility',
          title: rebate.name,
          body: rebate.text
        }))
      ]
    }));
  }
  return [];
};

export const getPage = async (link: string, locale: string): Promise<PageInterface> => {
  let locales = [];
  let response = await fetch(resourceUrl(PAGES_COLLECTION, PAGES_QUERY(link)));
  if (response.ok) {
    const { data } = await response.json();
    const page = data.find((item: Record<string, unknown>) => item.locale === locale);
    locales = data.map((item: Record<string, unknown>) => item.locale);
    if (page) {
      const result = { ...page, locales };
      response = await fetch(
        resourceUrl(BLOG_BELONGS_TO_PAGES_COLLECTION, BLOG_PAGE_RELATED_POST_IDS_QUERY(page.id))
      );
      if (response.ok) {
        const { data } = await response.json();
        if (data.length) {
          response = await fetch(
            resourceUrl(
              BLOG_COLLECTION,
              BLOG_BY_POST_IDS_QUERY(
                data.map(({ evc_blog_id }: any) => evc_blog_id),
                BLOG_RELATED_ARTICLES_SIZE
              )
            )
          );
          if (response.ok) {
            const { data: records } = await response.json();
            return { ...result, posts: records.map(transformBlogPost) };
          }
        }
      }
      return { ...result, posts: [] };
    }
  }
  return { id: '', faqs: [], posts: [], locales };
};

const BLOG_CATEGORY_BY_LINK_QUERY = (link: string, locale: string) => `filter={"link":{"_eq":"${link}"},"locale":{"_eq":"${locale}"}}`;
const BLOG_CATEGORY_ORIGINAL_QUERY = (original: string) => `filter={"original":{"_eq":"${original}"}}`;

export const getBlogCategory = async (link: string, locale: string): Promise<{
  category?: PostCategory,
  i18nLinks?: Record<string, string>
}> => {
  const response = await fetch(resourceUrl(BLOG_CATEGORIES_COLLECTION, BLOG_CATEGORY_BY_LINK_QUERY(link, locale)));
  if (response.ok) {
    const { data } = await response.json();
    if (data.length > 0) {
      const response = await fetch(resourceUrl(BLOG_CATEGORIES_COLLECTION, BLOG_CATEGORY_ORIGINAL_QUERY(data[0].original)));
      if (response.ok) {
        const { data } = await response.json();
        const category = data.find((item: Record<string, unknown>) => item.locale === locale);
        return category
          ? {
            category: {
              ...category,
              locales: data.map((item: Record<string, unknown>) => item.locale)
            },
            i18nLinks: data.reduce((links, item) => ({
              ...links,
              [item.locale]: `/blog/${item.link}`
            }), {})
          }
          : {};
      }
    }
  }
  return {};
};

export const getPostsByCategory = async (
  locale: string,
  categoryId: string,
  page: number,
  size = BLOG_PAGE_SIZE,
  asOffset = false
): Promise<PostCollection> => {
  const offset = asOffset ? page : (page - 1) * size;

  const response = await fetch(
    resourceUrl(BLOG_COLLECTION, BLOG_BY_CATEGORY_ID_JOIN({ categoryId, limit: size, offset, locale }))
  );


  if (response.ok) {
    const { data, meta } = await response.json();
    if (data.length) {
      return { data: data.map(transformBlogPost), total: meta.filter_count };
    }
  }

  return { data: [], total: 0 };
};

const withLocales = (items: SitemapItem[]) => {
  const locales: Record<string, string[]> = {};
  items.forEach((item) => {
    if (!locales[item.link]) {
      locales[item.link] = [];
    }
    if (!locales[item.link].includes(item.locale)) {
      locales[item.link].push(item.locale);
    }
    item.locales = locales[item.link];
  });

  return items;
};

export const getSitemapPages = async (): Promise<SitemapItem[]> => {
  const response = await fetch(resourceUrl(PAGES_COLLECTION, PAGES_SITEMAP_QUERY));
  if (response.ok) {
    const { data } = await response.json();
    return withLocales(data);
  }
  return [];
};

export const getSitemapCategories = async (): Promise<SitemapItem[]> => {
  let response = await fetch(resourceUrl(BLOG_CATEGORIES_COLLECTION, BLOG_CATEGORIES_LINKS_QUERY));
  if (response.ok) {
    const { data } = await response.json();
    response = await fetch(resourceUrl(BLOG_COLLECTION, BLOG_SITEMAP_LINKS_QUERY));
    if (response.ok) {
      const { data: groups } = await response.json();
      const lastmods = groups.reduce(
        (accum: Record<string, string>, group: ActiveCategoryGroup) => {
          const {
            active_category,
            max: { publish_at, date_updated }
          } = group;
          accum[active_category] = date_updated || publish_at;
          return accum;
        },
        {}
      );
      const linksLocales = await fetchLinksLocales(BLOG_CATEGORIES_COLLECTION, data);

      return data.map((item: PostCategory) => ({
        ...item,
        link: `blog/${item.link}`,
        lastmod: lastmods[item.id],
        ...createAlternatesAndLink(
          [
            ...(linksLocales[item.original] || [])
              .map((item) => ({ ...item, link: `blog/${item.link}` }))
          ],
          item.locale,
          `blog/${item.link}`
        )
      }));
    }
  }
  return [];
};

export const getRssFeed = async (locale: string): Promise<RssFeed[]> => {
  const response = await fetch(resourceUrl(BLOG_COLLECTION, BLOG_RSS_QUERY(locale)));

  if (response.ok) {
    const { data } = await response.json();

    return data.map((post: BlogPost) => ({
      ...post,
      description: post.meta_description,
      link: `/${post.link}`,
      lastmod: post.date_updated || post.publish_at,
      image: assetUrl(post.image)
    }));
  }
  return [];
};

const fetchOriginalsPromise = async (collection: string, originalsSlice: string[]) => {
  const response = await fetch(
    resourceUrl(collection, BLOG_ORIGINALS_QUERY(originalsSlice))
  );
  const { data } = await response.json();

  return data;
};

const fetchLinksLocales = async (collection: string, items: Record<string, string>[]) => {
  const originals = items.map((item) => item.original);
  const sliceTotal = 20;
  const sliceSize = SITEMAP_PAGE_SIZE / sliceTotal;
  const promises = [];
  for (let i = 0; i < SITEMAP_PAGE_SIZE; i = i + sliceSize) {
    const originalsSlice = originals.slice(i, i + sliceSize);
    promises.push(fetchOriginalsPromise(collection, originalsSlice));
  }
  const results = await Promise.all(promises);
  const records = results.flat(1).filter((r) => r !== undefined);
  const links = {};
  for (const { link, original, locale } of records) {
    if (!links[original]) {
      links[original] = [];
    }
    if (!links[original].find((lo) => lo.link === link)) {
      links[original].push({ locale, link });
    }
  }
  return links;
};

const createAbsoluteLink = ({ link, locale }: { link: string, locale: string }) => {
  return locale === I18N_DEFAULT_LOCALE ? `${HOST_URL}/${link}` : `${HOST_URL}/${locale}/${link}`;
};

const createAlternatesAndLink = (linksLocales: Array<{
  locale: string,
  link: string
}>, locale: string, relativeLink: string) => {
  let link = createAbsoluteLink({ link: relativeLink, locale });
  const alternates = (linksLocales || [])
    .map((l) => {
      if (l.locale === locale) {
        link = createAbsoluteLink(l);
      }
      return { ...l, link: createAbsoluteLink(l) };
    }).slice(0, 2);

  return { link, alternates };
};

const simpleFetch = async (collection, query, limit: number, offset: number) => {
  const response = await fetch(resourceUrl(collection, query(limit, offset)));
  if (!response.ok) {
    return [];
  }
  const { data } = await response.json();

  return data;
};

const complexFetch = async (collection, query, limit: number, offset: number) => {
  const sliceTotal = 20;
  const sliceSize = limit / sliceTotal;
  const promises = [];
  for (let i = 0; i < SITEMAP_PAGE_SIZE; i = i + sliceSize) {
    promises.push(simpleFetch(collection, query, sliceSize, i + offset));
  }
  const results = await Promise.all(promises);
  return results.flat(1).filter((r) => r !== undefined);
};

export const readSitemapPostsCache = async (page: number): Promise<string | null> => {
  const response = await fetch(`https://admin.energy5.com/items/energy5_cache/posts${page}.xml`);
  if (!response.ok) {
    return null;
  }
  const { data: { value } } = await response.json();

  return value;
};

export const getSitemapPosts = async (page: number): Promise<SitemapItem[]> => {
  const data = await complexFetch(
    BLOG_COLLECTION,
    BLOG_SITEMAP_QUERY,
    SITEMAP_PAGE_SIZE,
    (page - 1) * SITEMAP_PAGE_SIZE
  );
  const linksLocales = await fetchLinksLocales(BLOG_COLLECTION, data);

  // @ts-ignore
  return data.map(
    ({
       link,
       original,
       locale,
       date_updated,
       publish_at,
       image,
       title,
       summary,
       meta_description,
       comments
     }: BlogPost) => {
      return {
        title,
        description: meta_description,
        locale,
        lastmod: maxDate([comments?.length ? comments[0].publish_at : null, date_updated, publish_at]),
        images: [
          {
            src: assetUrl(image),
            title:
              title && typeof title.replaceAll === 'function'
                ? title.replaceAll('&', '')
                : 'Turnkey EV Charging Solutions'
          },
          ...extractImages(summary || '', title)
        ],
        ...createAlternatesAndLink(linksLocales[original], locale, link)
      };
    }
  );
};

export const getSitemapPostsPagesTotal = async (): Promise<number> => {
  const response = await fetch(resourceUrl(BLOG_COLLECTION, BLOG_SITEMAP_COUNT_QUERY));
  if (response.ok) {
    const { data } = await response.json();
    const count = data[0].count;
    return Math.ceil(count / SITEMAP_PAGE_SIZE);
  }

  return 0;
};

export const getSitemapLocations = async (
  page: number,
  size = SITEMAP_PAGE_SIZE,
  sort = 'id'
): Promise<SitemapItem[]> => {
  const response = await fetch(
    resourceUrl(
      RETAIL_LOCATIONS_COLLECTION,
      RETAIL_LOCATIONS_SITEMAP_QUERY(size, (page - 1) * size, sort)
    )
  );

  if (response.ok) {
    const { data } = await response.json();
    return data.map(
      ({ slug, date_updated, date_created, meta_title, meta_description }: RetailLocation) => {
        return {
          title: meta_title,
          description: meta_description,
          locale: 'en',
          locales: ['en'],
          link: ENERGY_RATES_ROUTE({ slug }),
          lastmod: date_updated || date_created
        };
      }
    );
  }
  return [];
};

export const getSitemapLocationsPagesTotal = async (): Promise<number> => {
  const response = await fetch(
    resourceUrl(RETAIL_LOCATIONS_COLLECTION, RETAIL_LOCATIONS_SITEMAP_COUNT_QUERY)
  );
  if (response.ok) {
    const { data } = await response.json();
    const count = data[0].count;
    return Math.ceil(count / SITEMAP_PAGE_SIZE);
  }

  return 0;
};

const extractImages = (html: string, title: string) => {
  const images = html.match(/<img [^>]*src="[^"]*"[^>]*>/gm);
  return images
    ? images
      .map((img) => ({
        src: img.replace(/.*src="([^"]*)".*/, '$1').trim(),
        title: img.replace(/.*title="([^"]*)".*/, '$1').replace('&', '') || title
      }))
      .filter((img) => !!img.src)
    : [];
};

export const getPostSummaryWithAnchors = (html: string) => {
  const match = html.match(/<h2>(.*?)<\/h2>/g);
  if (!match) {
    return { summary: html, anchors: [] };
  }
  const h2s = match.map((val) => val.replace(/<\/?b>/g, ''));

  let description = html;
  let anchors = [];
  for (let i = 0; i < h2s.length; i++) {
    const h2 = h2s[i];
    anchors.push(h2
      .replace('<h2>', '')
      .replace('</h2>', '')
    );
    const h2withAnchor = h2.replace('<h2>', `<h2 id="anchor-${i}">`);
    description = description.replace(h2, h2withAnchor);
  }

  return { description, anchors };
};

export const injectBanners = (summary: string, banners: string[]): string => {
  let rest = summary;
  let i = 0;
  const pieces = [];

  while (true) {
    if (!banners[i]) {
      pieces.push(rest);
      break;
    }
    if (rest.length < 1000) {
      pieces.push(rest, banners[i]);
      break;
    }
    const chunk = truncateBeforeBanners(rest, 1000);
    pieces.push(chunk, banners[i]);
    rest = rest.slice(chunk.length);
    i++;
  }

  return pieces.join('');
};

export const getPostSummaryWithAnchorsH3 = (html: string) => {
  const match = html.match(/<h3>(.*?)<\/h3>/g);
  if (!match) {
    return { summary: html, anchors: [] };
  }
  const h2s = match.map((val) => val.replace(/<\/?b>/g, ''));

  let summary = html;
  let anchors = [];
  for (let i = 0; i < h2s.length; i++) {
    const h2 = h2s[i];
    anchors.push(h2
      .replace('<h3>', '')
      .replace('</h3>', '')
    );
    const h2withAnchor = h2.replace('<h3>', `<h3 id="anchor-${i}">`);
    summary = summary.replace(h2, h2withAnchor);
  }

  return { summary, anchors };
};

export const createPostComment = async (data: CreatePostCommentRequest): Promise<void> => {
  await fetch(resourceWriteUrl(POST_COMMENTS_COLLECTION), {
    method: HttpMethod.POST,
    headers: {
      Authorization: `Bearer ${ENV.POST_COMMENTER_STATIC_TOKEN}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  });
};

export const getRetailLocation = async (slug: string): Promise<RetailLocation | null> => {
  const response = await fetch(
    resourceUrl(RETAIL_LOCATIONS_COLLECTION, RETAIL_LOCATIONS_BY_SLUG_QUERY(slug))
  );
  if (response.ok) {
    const { data } = await response.json();
    return data.length > 0 ? data[0] : null;
  }
  return null;
};

export const getNearRetailLocations = async (zipCode: string): Promise<RetailLocation[]> => {
  const response = await fetch(
    resourceUrl(RETAIL_LOCATIONS_COLLECTION, RETAIL_NEAR_LOCATIONS_QUERY(zipCode))
  );
  if (response.ok) {
    const { data } = await response.json();
    return data;
  }
  return [];
};

export const getRetailLocationByZipCode = async (
  zipCode: string
): Promise<RetailLocation | null> => {
  const response = await fetch(
    resourceUrl(RETAIL_LOCATIONS_COLLECTION, RETAIL_LOCATIONS_BY_ZIP_CODE_QUERY(zipCode))
  );
  if (response.ok) {
    const { data } = await response.json();
    return data.length > 0 ? data[0] : null;
  }
  return null;
};

const transformBlogPost = (data: Record<string, any>): BlogPost => {
  const categories = (data.categories || [])
    .map((item: Record<string, any>) => item.evc_blog_categories_id)
    .filter((id) => !!id)
  ;
  const sections = (data.sections || []).map((item: Record<string, any>) => ({
    ...item,
    image: item.image ? assetUrl(item.image) : null
  }));
  const active_category = data.active_category
    ? data.active_category
    : categories.find((category: any) => category.listed) || categories[0];

  return {
    ...data,
    categories,
    sections,
    image: assetUrl(data.image),
    active_category: active_category || {}
  } as BlogPost;
};

const SERVICES_COLLECTION = 'energy5_services';

const SERVICES_BY_LOCALE_QUERY = (locale: string) =>
  `filter=${JSON.stringify({ locale: { _eq: locale }, status: { _eq: 'published' } })}`;
const SERVICE_BY_NAME_QUERY = (locale: string, name: string) =>
  `fields=*,faqs.*.*,utilities.*.*,blog_posts.*&filter=${JSON.stringify({
    locale: { _eq: locale },
    status: { _eq: 'published' },
    key: { _eq: name }
  })}`;

export const getServices = async (
  locale_future: string
): Promise<BlogPost[]> => {
  const locale = 'en';
  const response = await fetch(
    resourceUrl(SERVICES_COLLECTION, SERVICES_BY_LOCALE_QUERY(locale))
  );

  if (response.ok) {
    const { data } = await response.json();
    return data;
  }
  return [];
};

export const getService = async (
  locale_future: string,
  name: string
): Promise<E5Service | null> => {
  const locale = 'en';
  const response = await fetch(
    resourceUrl(SERVICES_COLLECTION, SERVICE_BY_NAME_QUERY(locale, name))
  );

  if (response.ok) {
    const { data } = await response.json();

    if (!data.length) {
      return null;
    }

    const service = data[0];
    service.faqs = service.faqs.map(({ energy5_frequently_asked_questions_id: item }) => ({
      id: item.id,
      question: item.question,
      answer: item.answer
    }));
    service.utilities = service.utilities.map(({ energy5_service_utilities_id: item }) => item);
    service.blog_posts = service.blog_posts.map(({ evc_blog_id: item }) => item);
    return service;
  }

  return null;
};

const SERVICE_CONTACT_FORM_COLLECTION = 'energy5_services_contacts_submits';

export const storeServiceContactFormData = async (data: Record<string, string>): Promise<void> => {
  try {
    await fetch(resourceWriteUrl(SERVICE_CONTACT_FORM_COLLECTION), {
      method: HttpMethod.POST,
      headers: {
        Authorization: `Bearer ${ENV.POST_COMMENTER_STATIC_TOKEN}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });
  } catch (e) {
    console.log(e);
  }
};

const SERVICE_DEFINED_ARTICLES_BY_IDS = (locale: string, ids: number[]) =>
  `fields=*,active_category.*,categories.evc_blog_categories_id.*&filter=${JSON.stringify({
    locale: { _eq: locale },
    id: { _in: ids }
  })}`;

export const getServiceDefinedArticles = async (
  locale: string,
  ids: number[]
): Promise<BlogPost[]> => {
  const response = await fetch(
    resourceUrl(BLOG_COLLECTION, SERVICE_DEFINED_ARTICLES_BY_IDS(locale, ids))
  );

  if (response.ok) {
    const { data } = await response.json();
    return data.map(transformBlogPost);
  }

  return [];
};

const SHOP_PRODUCTS_COLLECTION = 'energy5_shop_products';

const SHOP_PRODUCTS_QUERY = (category: string, link = '') => link ?
  `fields=*,images.*&filter[link]=${link}&filter[category]=${category}&filter[status]=published` :
  `fields=*,images.*&filter[category]=${category}&filter[status]=published`;

export const getShopProducts = async (category: string): Promise<ShopProduct[]  > => {
  const response = await fetch(
    resourceUrl(SHOP_PRODUCTS_COLLECTION, SHOP_PRODUCTS_QUERY(category))
  );

  if (response.ok) {
    const { data } = await response.json();

    return data.map((item: Record<string, any>) => ({
      ...item,
      images: item.images.map(({ directus_files_id }) => assetUrl(directus_files_id, 'webp')),
    }));
  }
  return [];
};

export const getShopProduct = async (category: string, link: string): Promise<ShopProduct | null> => {
  const response = await fetch(
    resourceUrl(SHOP_PRODUCTS_COLLECTION, SHOP_PRODUCTS_QUERY(category, link))
  );

  if (response.ok) {
    const { data } = await response.json();
    if (data.length) {
      return data.map((item: Record<string, any>) => ({
        ...item,
        images: item.images.map(({ directus_files_id }) => assetUrl(directus_files_id, 'webp')),
        pdf: item.pdf ? assetUrl(item.pdf, 'pdf') : null,
      }))[0];
    }
  }
  return null;
};

const BLOG_ROOT_CATEGORIES_QUERY = (locale: string) =>
  `fields=id,link,title&filter={"parent_id":{"_null":true},"locale":{"_eq":"${locale}"}}&sort=original`; // &sort=sort

export const getRootCategories = async (locale: string): Promise<PostCategory[]> => {
  const response = await fetch(
    resourceUrl(BLOG_CATEGORIES_COLLECTION, BLOG_ROOT_CATEGORIES_QUERY(locale))
  );
  if (response.ok) {
    const { data } = await response.json();
    return data;
  }
  return [];
};

const BLOG_CATEGORY_BY_ID_QUERY = `fields=id,link,title`;
export const getCategoryById = async (id: string): Promise<PostCategory | null> => {
  const response = await fetch(
    resourceUrl(BLOG_CATEGORIES_COLLECTION + '/' + id, BLOG_CATEGORY_BY_ID_QUERY)
  );
  if (response.ok) {
    const { data } = await response.json();
    return data;
  }
  return null;
};

const BLOG_CHILD_CATEGORIES_QUERY = (parentId: string, locale: string) =>
  `fields=id,link,title&filter={"parent_id":{"_eq":${parentId}},"locale":{"_eq":"${locale}"}}&sort=original`; // &sort=sort

export const getChildCategories = async (parentId: string, locale: string): Promise<PostCategory[]> => {
  const response = await fetch(
    resourceUrl(BLOG_CATEGORIES_COLLECTION, BLOG_CHILD_CATEGORIES_QUERY(parentId, locale))
  );

  if (response.ok) {
    const { data } = await response.json();
    return data;
  }
  return [];
};

export const getGroupedPosts = async (categories: PostCategory[], locale: string): Promise<GroupedPosts[]> => {
  const promises = [];
  for (const category of categories) {
    promises.push(new Promise(async (resolve) => {
      const { data } = await getPostsByCategory(locale, category.id, 1, 4);
      resolve({ ...category, posts: data });
    }));
  }

  return Promise.all(promises);
};

export const getNonEnglishCategory = async (originalId: string, locale: string) => {
  const response = await fetch(
    resourceUrl(BLOG_CATEGORIES_COLLECTION + '/' + originalId )
  )
  if (response.ok) {
    const { data: original } = await response.json();

    {
      const response = await fetch(
        resourceUrl(BLOG_CATEGORIES_COLLECTION, `fields=id&limit=1&filter=${JSON.stringify({locale:{_eq:locale},original:{_eq:original.original}})}` )
      )

      if (response.ok) {
        const { data } = await response.json();

        return data.length ? data[0]: null;
      }
    }
  }
};

const BANNERS_COLLECTION = 'energy5_banners';
const BANNERS_BY_CATEGORIES_QUERY = (ids: string[]) => `fields=banners.*&filter={"categories":{"evc_blog_categories_id":{"_in":${JSON.stringify(ids)}}}}`;

export const getBanners = async (post: BlogPost) => {
  const categoryIds = [post.active_category.id, ...post.categories.map((c) => c.id)];
  const response = await fetch(resourceUrl(BANNERS_COLLECTION, BANNERS_BY_CATEGORIES_QUERY(categoryIds)));
  if (response.ok) {
    const { data } = await response.json();

    return data.length ?
      data.map((item) => item.banners)[0].map((banner) => banner.content) :
      [];
  }
  return [];
};

const BLOG_BY_LINK_QUERY = (link: string, locale: string) =>
  `fields=id,image,title,summary,intro,time_to_read,comments_json,summary,date_created,date_updated,publish_at,link,original,locale,meta_title,meta_description,active_category.*,categories.u1_blog_categories_id.*&filter={"publish_at":{"_lte":"now"},"link":{"_eq":"${link}"},"locale":{"_eq":"${locale}"}}`;
const BLOG_BY_ORIGINAL_QUERY = (original: string) =>
  `fields=*,active_category.*,categories.evc_blog_categories_id.*&filter={"original":{"_eq":"${original}"}}`;

export const getBlogPost = async (link: string, locale: string): Promise<{
  post?: BlogPost,
  i18nLinks?: Record<string, string>
}> => {
  const response = await fetch(resourceUrl(BLOG_COLLECTION, BLOG_BY_LINK_QUERY(link, locale)));
  if (response.ok) {
    const { data } = await response.json();
    if (!data.length) {
      return {};
    }
    {
      const response = await fetch(resourceUrl(BLOG_COLLECTION, BLOG_BY_ORIGINAL_QUERY(data[0].original)));
      if (response.ok) {
        const { data } = await response.json();
        const blog = data.find((item: Record<string, unknown>) => item.locale === locale);
        return blog
          ? {
            post: transformBlogPost({
              ...blog,
              locales: data.map((item: Record<string, unknown>) => item.locale)
            }),
            i18nLinks: data.reduce((links, item) => ({
              ...links,
              [item.locale]: `/${item.link}`
            }), {})
          }
          : {};
      }
    }
  }
  return {};
};

const BLOG_COUNT_QUERY = (locale: string) =>
  `aggregate[count]=id&filter={"status":{"_eq":"published"},"publish_at": {"_lte":"now"},"locale": {"_eq":"${locale}"}}`;
const BLOG_RELATED_QUERY = (offset: number, limit: number, locale: string) =>
  `fields=id,title,image,link&filter={"status":{"_eq":"published"},"publish_at":{"_lte":"now"},"locale":{"_eq":"${locale}"}}&limit=${limit}&offset=${offset}`;

export const getRelatedArticles = async (post: BlogPost): Promise<BlogPost[]> => {
  const response = await fetch(resourceUrl(BLOG_COLLECTION, BLOG_COUNT_QUERY(post.locale)));
  if (response.ok) {
    const { data } = await response.json();
    const count = Number(data[0].count);
    const offset = randomInt(BLOG_RELATED_ARTICLES_SIZE, count - BLOG_RELATED_ARTICLES_SIZE);
    {
      const response = await fetch(resourceUrl(BLOG_COLLECTION, BLOG_RELATED_QUERY(offset, BLOG_RELATED_ARTICLES_SIZE, post.locale)));
      if (response.ok) {
        const { data } = await response.json();
        return data.map(transformBlogPost);
      }
    }
  }
  return [];
};
