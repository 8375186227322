import React, { createContext, FC, ReactNode } from 'react';

export type I18nValue = {
  dictionary?: Record<string, string>;
  links?: Record<string, string>;
}

interface I18nProviderProps {
  children: ReactNode;
  value: I18nValue;
}

export const i18nContext = createContext({});

const Provider = i18nContext.Provider;

const I18nProvider: FC<I18nProviderProps> = ({ children, value }) => {
  return <Provider value={value} children={children} />;
};

export default I18nProvider;
