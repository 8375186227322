import { PlansState, plansStateToRequest } from 'utils/retail/plans';
import { objectToQueryString } from 'utils/common/http';
import { CustomerType } from 'services/orders/types';
import { customerViewFromType } from 'utils/retail';

export const RETAIL_BASE_ROUTE = '/retail';

export const RETAIL_COMPARE_ENERGY_RATES_ROUTE = (zipCode = '') =>
  `/compare-energy-rates${zipCode ? '/' + zipCode : ''}`;

export const RETAIL_COMPARE_ENERGY_RATES_FULL_ROUTE = (
  customerType: CustomerType,
  zipCode?: string
) => `/compare-energy-rates/${customerViewFromType(customerType)}${zipCode ? '/' + zipCode : ''}`;

export const RETAIL_ENERGY_RATES_ROUTE = (state: Partial<PlansState>) => {
  const { zipCode, customerType, commodity, ...query } = plansStateToRequest(state);
  const route = `${RETAIL_BASE_ROUTE}/energy-rates/${customerViewFromType(
    customerType
  )}/${commodity.toLowerCase()}/${zipCode}`;
  return Object.keys(query).length > 0 ? `${route}?${objectToQueryString(query)}` : route;
};

const ENERGY_RATES_BASE_ROUTE = '/energy-rates';

export const ENERGY_RATES_ROUTE = (params: Record<string, string | number>) => {
  const { slug, ...query } = params;
  const route = `${ENERGY_RATES_BASE_ROUTE}/${slug}`;
  return Object.keys(query).length > 0 ? `${route}?${objectToQueryString(query)}` : route;
};

export const ENERGY_RATES_PLAN_ROUTE = (planId: string, query: Record<string, string>) =>
  `${ENERGY_RATES_BASE_ROUTE}/plan/${planId}?${objectToQueryString(query)}`;
