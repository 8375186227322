import { CustomerType, CustomerTypeView } from 'services/orders/types';
import { capitalizeFirstLetter } from 'utils/common/string';

export const customerTypeFromView = (view: CustomerTypeView): CustomerType => {
  switch (view) {
    case CustomerTypeView.business:
      return CustomerType.COMMERCIAL;
    case CustomerTypeView.home:
      return CustomerType.RESIDENTIAL;
  }
};

export const customerViewFromType = (type: CustomerType, capitalize = false): string => {
  switch (type) {
    case CustomerType.COMMERCIAL:
      return capitalize
        ? capitalizeFirstLetter(CustomerTypeView.business)
        : CustomerTypeView.business;
    case CustomerType.RESIDENTIAL:
      return capitalize ? capitalizeFirstLetter(CustomerTypeView.home) : CustomerTypeView.home;
  }
};
