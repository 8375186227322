import { GetBestPlansRequest, GetPlansRequest, Price, Term } from 'services/quotes/types';
import { EFFECTIVE_MIN_DAY } from 'config/orders/constants';
import { fullMonths } from 'utils/common/date';
import { Classification, Commodity, State, Utility, Zone } from 'services/delivery/types';
import { AnnualUsageUnit, CustomerType } from 'services/orders/types';
import { objectRemoveEmptyValues } from 'utils/common/object';

/**
 * Estimates savings = min(0, (price to compare - price) * term / 12 * annual usage)
 * */
export const calcEstimatesSavings = (
  priceToCompare: number,
  annualUsage: number,
  price: Price,
  term: Term
): number => Math.max(0, (priceToCompare / 100 - price.amount) * (term.value / 12) * annualUsage);

export const calcMinEffectiveDate = (): Date => {
  const isLessThenMinDay = new Date().getDate() < EFFECTIVE_MIN_DAY;
  const effectiveDate = new Date();
  effectiveDate.setDate(1);
  effectiveDate.setMonth(effectiveDate.getMonth() + (isLessThenMinDay ? 1 : 2));
  return effectiveDate;
};

export const effectiveDateToString = (date: Date): string => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;

  return `${year}-${month < 10 ? '0' : ''}${month}`;
};

export const formatEffectiveDate = (date: string): string => {
  const [month, year] = date.split(', ');
  const monthNumber = fullMonths.findIndex((name) => month === name) + 1;
  return `${year}-${monthNumber < 10 ? '0' : ''}${monthNumber}`;
};

export const humanFormatEffectiveDate = (date: Date): string =>
  `${fullMonths[date.getMonth()]}, ${date.getFullYear()}`;

export interface PlansFilter {
  customerType: CustomerType;
  zipCode: string;
  state: State;
  commodity: Commodity;
  utility: Utility;
  zone: Zone;
  rateClass: Classification;
  annualUsage: number | string;
  unit: AnnualUsageUnit;
}

export interface PlansSorting {
  sort: string;
}

export interface PlansPagination {
  pageSize: number;
  page: number;
}

export interface PlansTableFilter {
  effectiveDate: string;
  term: number[];
}

export type PlansState = PlansFilter & PlansSorting & PlansPagination & PlansTableFilter;

export type BestPlansState = PlansFilter & {
  effectiveDate: string;
};

export const plansStateToRequest = (state: Partial<PlansState>): GetPlansRequest => {
  return objectRemoveEmptyValues({
    utilityId: state.utility?.utilityId,
    annualUsage: state.annualUsage ? Number(state.annualUsage) : null,
    unit: state.unit,
    commodity: state.commodity || null,
    state: state.state ? state.state.state : null,
    customerType: state.customerType,
    zipCode: state.zipCode,
    zoneId: state.zone ? state.zone.zoneId : null,
    classificationId: state.rateClass ? state.rateClass.classificationId : null,
    page: state.page,
    size: state.pageSize,
    sort: state.sort,
    effectiveDate: state.effectiveDate ? formatEffectiveDate(state.effectiveDate) : null,
    term: state.term ? (typeof state.term === 'string' ? state.term : state.term.join(',')) : null,
  }) as GetPlansRequest;
};

export const bestPlansStateToRequest = (state: BestPlansState): GetBestPlansRequest => {
  return objectRemoveEmptyValues({
    utilityId: state.utility?.utilityId,
    annualUsage: state.annualUsage ? Number(state.annualUsage) : null,
    unit: state.unit,
    commodity: state.commodity || null,
    state: state.state ? state.state.state : null,
    customerType: state.customerType,
    zipCode: state.zipCode,
    zoneId: state.zone ? state.zone.zoneId : null,
    classificationId: state.rateClass ? state.rateClass.classificationId : null,
    preferredTerms: '12,18,24,36',
    effectiveDate: state.effectiveDate ? formatEffectiveDate(state.effectiveDate) : null,
  }) as GetBestPlansRequest;
};
