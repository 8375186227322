import React, { useEffect } from 'react';
import { AppProps } from 'next/app';

import { setENV } from 'evcharging/app';
import I18nProvider from 'evcharging/providers/I18nProvider';
import defaultDictionary from 'i18n/en/common';

const MyApp = (props: AppProps) => {
  const { Component, pageProps } = props;

  if (pageProps.ENV) {
    setENV(pageProps.ENV);
  }

  useEffect(() => {
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);

  return (
    <I18nProvider value={{
      dictionary: pageProps.i18n ?? defaultDictionary,
      links: pageProps.i18nLinks,
    }}>
      <Component {...pageProps} />
    </I18nProvider>
  );
};

export default MyApp;
