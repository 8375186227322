export const stringRandom = (): string => {
  return Math.random().toString(36).substring(7);
};

export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const truncateText = (text: string, count: number) => {
  if (text.length <= count) {
    return text;
  }
  const subString = text.slice(0, count - 1);
  return subString.slice(0, subString.lastIndexOf(" ")) + '...';
};

export const truncateBeforeBanners = (text: string, count: number) => {
  if (text.length <= count) {
    return text;
  }
  const subString = text.slice(0, count - 1);
  return subString.slice(0, subString.lastIndexOf(">") + 1);
};

export const camelToSnakeCase = (str) =>
  str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);


export const regexIndexOf = (text, re, from) => {
  const indexInSuffix = text.slice(from).search(re);
  return indexInSuffix < 0 ? indexInSuffix : indexInSuffix + from;
}