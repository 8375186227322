import { camelToSnakeCase } from 'utils/common/string';

export const objectRenameKeys = <R>(keysMap, obj): R =>
  Object.keys(obj).reduce(
    (acc, key) => ({
      ...acc,
      ...{ [keysMap[key] || key]: obj[key] },
    }),
    {} as R
  );

export const areObjectsEqual = (
  a: Record<string, any>,
  b: Record<string, any>,
  ignoreKeys: string[] = []
): boolean => {
  for (const key of Object.keys(a)) {
    if (ignoreKeys.includes(key)) {
      continue;
    }
    if (a[key] === b[key]) {
      continue;
    }
    if (typeof a[key] !== 'object') {
      return false;
    }
    if (!areObjectsEqual(a[key], b[key], ignoreKeys)) {
      return false;
    }
  }
  return true;
};

export const objectRemoveEmptyValues = (obj: Record<string, any>) =>
  objectFromEntries(Object.entries(obj).filter(([_, v]) => v !== null && v !== undefined));

export const objectKeysToSnakeCase = (obj: Record<string, any>) => {
  return Object.keys(obj).reduce((acc, key) => ({ ...acc, [camelToSnakeCase(key)]: obj[key] }), {});
};

const objectFromEntries = (iterable: Array<[string, any]>) =>
  [...iterable].reduce((obj, [key, val]) => {
    obj[key] = val;
    return obj;
  }, {});
