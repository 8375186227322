import { I18nPage } from "./types/pages.enum";

export const getDictionary = async (locale: string, defaultLocale: string, pages: I18nPage[]) => {
  let dictionary = {
    ...(await import(`i18n/${defaultLocale}/common`)).default,
    ...(await import(`i18n/${locale}/common`)).default
  };

  try {
    for (let i = 0; i < pages.length; i++) {
      dictionary = {
        ...dictionary,
        ...(await import(`i18n/${defaultLocale}/${pages[i]}`)).default,
        ...(await import(`i18n/${locale}/${pages[i]}`)).default
      }
    }

    return dictionary
  } catch (e) {
    console.log(e)
    return {};
  }
};
