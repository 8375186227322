export const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];
export const fullMonths = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const dateToTimeString = (date: Date): string => {
  return date ? date.toTimeString().split(' ')[0] : '';
};

export const dateToHumanFormat = (date: Date): string => {
  const day = date.getDate();
  const month = fullMonths[date.getMonth()];
  const year = date.getFullYear();
  return `${month} ${day} ${year}`;
};
