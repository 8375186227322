import { State } from 'services/delivery/types';

export enum ServiceType {
  RETAIL_ENERGY = 'RETAIL_ENERGY',
}

export enum CustomerType {
  RESIDENTIAL = 'RESIDENTIAL',
  COMMERCIAL = 'COMMERCIAL',
}

export enum CustomerTypeView {
  home = 'home',
  business = 'business',
}

export enum AnnualUsageUnit {
  THERM = 'THERM',
  KWH = 'KWH',
  CCF = 'CCF',
}

export interface AnnualUsage {
  amount: number;
  unit: AnnualUsageUnit;
}

interface UtilityAccount {
  accountNumber: string;
  serviceAddress: {
    address: string;
    extendedAddress: string;
    city: string;
    county: string;
    state: string;
    zipCode: string;
  };
  annualUsage: AnnualUsage;
}

export interface OrderLineItem {
  planId: string;
  utilityAccounts: UtilityAccount[];
  fileIds: string[];
}

export interface CreateResidentialOrderRequest {
  serviceType: ServiceType;
  customerInfo: {
    type: CustomerType;
    firstName: string;
    middleName?: string;
    relationship?: string;
    lastName: string;
    emailAddress: string;
    phoneNumber: string;
    mailingAddress: {
      address: string;
      city: string;
      county: string;
      state: string;
      zipCode: string;
      extendedAddress?: string;
    };
    esignature?: string;
  };
  orderLineItems: OrderLineItem[];
}

export interface CreateCommercialOrderRequest {
  serviceType: ServiceType;
  customerInfo: {
    type: CustomerType;
    legalEntity: string;
    dba: string;
    firstName: string;
    middleName?: string;
    lastName: string;
    jobTitle: string;
    emailAddress: string;
    phoneNumber: string;
    mailingAddress: {
      address: string;
      extendedAddress: string;
      city: string;
      county: string;
      state: string;
      zipCode: string;
    };
  };
  orderLineItems: OrderLineItem[];
}

export interface CustomerInformation {
  type: CustomerType;
  legalEntity?: string;
  dba?: string;
  firstName: string;
  middleName?: string;
  lastName: string;
  emailAddress: string;
  phoneNumber: string;
}

export interface AddressInterface<S = State> {
  address: string;
  city: string;
  state: S;
  zipCode: string;
  county?: string;
  extendedAddress?: string;
}

export interface LightingRetrofitOrderLineItem {
  productId: string;
  quantity: number;
}

export interface CreateLightingRetrofitOrder {
  orderLineItems: LightingRetrofitOrderLineItem[];
  customerInfo: CustomerInformation;
  shippingAddress: AddressInterface<string>;
  billingAddress: AddressInterface<string>;
}

export interface OrdersServiceInterface {
  createCommercialOrder(request: CreateCommercialOrderRequest): Promise<string>;
  createResidentialOrder(request: CreateResidentialOrderRequest): Promise<string>;
  createLightingRetrofitOrder(request: CreateLightingRetrofitOrder): Promise<string>;
}
